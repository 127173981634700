import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';

import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';

import { geoLocation, getCookie, isShownPrivacy, setCookie } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

import { DigitalDLTimeLimitError } from '@FLOW_V2_FLOW/components/ErrorScreens/DigitalDLTimeLimitError';

import { CaptureNSWDigitalDriverLicenceScreen } from '@lib/pages/v2/CaptureNSWDigitalDriverLicenceScreen';
import { ManualEntryScreen } from '@lib/pages/v2/ManualEntryScreen';
import { waitUntilLastCardStorageIsReady } from '@FLOW_V2_FLOW/services/storageCheckAPI';
import { getTransactionToken } from '@lib/utils/transactionToken';
import { isTimeLimitOKForDigitalScreenShot } from '@lib/utils/digitalDLCheck';
import {
  FLOW_V2_DOC_NOT_ACCEPTED_TRY_AGAIN_MODE,
  HIDE_FOOTER_UPLOAD_SPINNER,
  LOCATION_REQUIRED,
  LOCATION,
  ENABLE_VISA_AFTER_PASSPORT
} from '@spotMobileConfig';
import {
  CouldNotRecognise,
  DocNotAccepted,
  PoorQuality,
  Recapture
} from '../VerifyDetails/VerifyDetails.errors';
import { EnableLocation, LocationSettings } from './Capture.error';
import classes from '../../components/Contents/IdSelection/IdSelection.style.module.scss';
import { Error500 } from '../../errors';
import { BackOfCard, CaptureTips, IdSelectionContent } from '../../components/Contents';
import { CaptureVisa, LoadingSpinner } from '../../components';

class Capture extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onExit: PropTypes.func,
    nextCapture: PropTypes.string,
    flowType: PropTypes.string,
    frontParams: PropTypes.object,
    selectedDiffId: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isFlowV2DiffId: PropTypes.bool,
    captureVisa: PropTypes.bool,
    resetExtractedInfo: PropTypes.func,
    resetConfirmedInfo: PropTypes.func,
    onGeoLocation: PropTypes.func,
    onCloseAcceptedDocs: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    verify: PropTypes.bool,
    setDocId: PropTypes.func,
    onSelectLanguage: PropTypes.func,
    engine4Config: PropTypes.object,
    ipCountryCode: PropTypes.string,
    setExtractedInfo: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.element = null;
    this.isComponentMounted = false;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      idType: 'AUS_AUTO_DRIVERLICENCE',
      progressBar: 0,
      backOfCard: false,
      isUploading: false,
      isLoading: false,
      isWaiting: false,
      error: null,
      geolocation: null,
      showCaptureVisa: false,
      showInvalidCardsModal: false,
      acceptableDocs: false,
      showCaptureTips: '',
      showCaptureDigitalIdScreen: false,
      showManualEntryScreen: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { frontParams, nextCapture } = this.props;

    if (frontParams.idType) {
      if (nextCapture === 'back') {
        this.setState({
          backOfCard: true
        });
      }
    }
  }

  componentDidMount() {
    const {
      isFlowV2DiffId,
      captureVisa = false,
      selectedDiffId,
      resetExtractedInfo,
      resetConfirmedInfo,
      onGeoLocation,
      onExit
    } = this.props;
    this.isComponentMounted = true;
    resetExtractedInfo();
    resetConfirmedInfo();

    if (captureVisa) {
      this.setState({
        showCaptureVisa: captureVisa,
        idType: selectedDiffId.type
      });
    }

    if (LOCATION)
      geoLocation()
        .then((geolocation) => {
          onGeoLocation(geolocation);
          this.setState({ geolocation });
        })
        .catch(() => {
          if (!LOCATION_REQUIRED) {
            onGeoLocation(null);
            return;
          }
          const error = {
            component: EnableLocation,
            props: {
              buttons: [
                {
                  label: localizedString('exit'),
                  variant: 'outline',
                  onClick: () => onExit()
                },
                {
                  label: localizedString('enable'),
                  onClick: () => {
                    const error = {
                      component: LocationSettings,
                      props: {
                        buttons: [
                          {
                            label: localizedString('exit'),
                            variant: 'outline',
                            onClick: () => onExit()
                          },
                          {
                            label: localizedString('checkAgainLabel'),
                            onClick: () => {
                              geoLocation()
                                .then((geolocation) =>
                                  this.setState({
                                    geolocation,
                                    error: null
                                  })
                                )
                                .catch(() => null);
                            }
                          }
                        ]
                      }
                    };
                    this.setState({ error });
                  }
                }
              ]
            }
          };
          this.setState({ error });
        });
    APIs.status('selectId', { resetTables: isFlowV2DiffId || captureVisa ? 'no' : 'yes' });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Go to handle Select
   * @return {Void}
   */
  handleOnShowAcceptableDocsClick = () => {
    this.setState({ acceptableDocs: true });
  };

  /**
   * Go to the next step
   *
   */
  handleNextStep() {
    const { idType } = this.state;
    if (!idType) return;

    this.element.click();
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  handleGoBack(e) {
    e.preventDefault();
    const { onCloseAcceptedDocs, onGoBack } = this.props;
    const { acceptableDocs = false, backOfCard } = this.state;

    if (acceptableDocs) {
      this.setState({
        acceptableDocs: false
      });
      onCloseAcceptedDocs();
    } else if (backOfCard) {
      this.setState({
        backOfCard: false
      });
      APIs.status('selectId', { resetTables: 'yes' });
    } else {
      const { backurl } = document.body.dataset;
      if (backurl && !isShownPrivacy('FLOW_V2')) {
        window.location.href = backurl;
      } else {
        onGoBack();
      }
    }
  }

  /**
   * When user happy with photo.
   * @param {ChangeEvent} e
   */
  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
  }

  /**
   * Handle Visa Upload
   * @return {Void}
   */
  handleCaptureVisa = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadVisaImage(imagefile);
  };

  /**
   * Go to the next step on Visa Upload
   * @return {Void}
   */
  handleSkip = () => {
    const { geolocation, verifyDetails } = this.state;
    this.props.setFrontIDParams({});
    this.setState({
      showCaptureVisa: false
    });
    const nextStepOptions = { geolocation, verifyDetails };
    this.props.onNextStep(nextStepOptions);
  };

  handleNextStepAfterManualScreen() {
    const { onNextStep } = this.props;
    onNextStep({ isDataOnlyCompleted: true });
  }

  onClickedFooterTakePhoto() {
    const { backOfCard } = this.state;

    if (backOfCard) {
      this.element.click();
    } else {
      this.handleNextStep();
    }
  }

  onClickedCaptureBackCard() {
    this.element.click();
  }

  onCaptureAgain() {
    this.setState({ showCaptureTips: '' });
    this.onClickedFooterTakePhoto();
  }

  // eslint-disable-next-line class-methods-use-this
  formatDay = (value) => {
    if (!value || value === '') {
      return '';
    }

    let parts;
    if (value.includes('-')) {
      parts = value.split('-');
    } else {
      parts = value.split('/');
    }

    const [day, month, year] = parts;
    return `${day}-${month}-${year}`;

    /* if (!value || value === '') {
            return '';
        }

        try {
            let dateString = Moment(value).format('DD-MM-YYYY')
            if (dateString === 'Invalid date') {
                return ''
            }

            return dateString
        } catch (e) {

        }

        return '' */
  };

  openInvalidCardsModal = (e) => {
    e.preventDefault();
    this.setState({
      showInvalidCardsModal: true
    });
  };

  uploadImage(imagefile, idType) {
    APIs.status('capturingId');
    this.setState({
      showCaptureVisa: false
    });
    const {
      frontParams,
      flowType,
      verify,
      selectedDiffId,
      isFlowV2DiffId,
      setFrontIDParams,
      onCloseAcceptedDocs,
      setDocId,
      onNextStep,
      setExtractedInfo
    } = this.props;
    const { geolocation, backOfCard } = this.state;

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    let params;
    if (backOfCard) {
      params = { ...frontParams, backFile: imagefile };
      setFrontIDParams({});
    } else {
      params = { frontFile: imagefile };
      setFrontIDParams(params);
    }
    params.flowType = flowType;
    params.isDiffId = isFlowV2DiffId && !!selectedDiffId;

    this.setState({ isUploading: true, backOfCard: false, acceptableDocs: false });
    APIs.uploadImage(
      params,
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => this.setState({ progressBar: width })
      },
      '/api/v4'
    )
      .then(({ status, token, msg: error }) => {
        const { isUploading } = this.state;
        if (!isUploading) {
          return;
        }

        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }
          return;
        }

        this.setState({ isUploading: false });

        if (token) {
          const noBack = !backOfCard;

          this.setState({ isLoading: true });
          const otherParams = {
            isEngineV4: true,
            isDiffId: isFlowV2DiffId && !!selectedDiffId,
            flowType
          };

          if (typeof selectedDiffId === 'object' && selectedDiffId !== null) {
            otherParams.idType = selectedDiffId.type;
          }

          if (idType) {
            otherParams.idType = idType;
          }

          APIs.extractIdentifyInfo(token, noBack, otherParams)
            .then(
              async ({
                data: cardData,
                code,
                poorQuality = null,
                docAccepted = true,
                captureBackCard = false,
                ignoreBackCardOfAUStates = ['NSW', 'TAS'],
                useIdNumber = false,
                isOldTurkishDL = false,
                poll = false,
                disableVerifyDetailsScreen = false
              }) => {
                const { isLoading } = this.state;
                if (!isLoading) {
                  return;
                }

                if (poll) {
                  const transactionToken = getTransactionToken();
                  await waitUntilLastCardStorageIsReady({ transactionToken });
                }

                if (!isTimeLimitOKForDigitalScreenShot(cardData)) {
                  const buttons = [
                    {
                      label: localizedString('tryAgain'),
                      onClick: () => {
                        this.setState({ error: null, showCaptureDigitalIdScreen: true });
                      }
                    }
                  ];

                  const error = {
                    component: DigitalDLTimeLimitError,
                    props: { buttons }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                let buttons = [
                  {
                    label: localizedString('back'),
                    variant: 'transparent',
                    onClick: () => {
                      setFrontIDParams({});
                      this.setState({ error: null });
                      APIs.status('selectId', { resetTables: 'yes' });
                    },
                    dataTestId: 'capture-backBtn'
                  }
                ];

                if (docAccepted === false) {
                  if (FLOW_V2_DOC_NOT_ACCEPTED_TRY_AGAIN_MODE) {
                    buttons = [
                      {
                        label: localizedString('tryAgain'),
                        onClick: () => {
                          setFrontIDParams({});
                          this.setState({ error: null });
                        }
                      }
                    ];
                  } else {
                    buttons.push({
                      label: localizedString('viewAcceptedIDs'),
                      onClick: () => {
                        onCloseAcceptedDocs();
                        setFrontIDParams({});
                        this.setState({ error: null, acceptableDocs: true });
                      }
                    });
                  }

                  const error = {
                    component: DocNotAccepted,
                    props: { buttons }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                const viewTipsButtonLabel = localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON');

                if (code === '223') {
                  buttons.push({
                    label: localizedString('tryAgain'),
                    onClick: () => {
                      setFrontIDParams({});
                      this.setState({ error: null });
                    }
                  });
                  const error = {
                    // component: FaceNotDetectedInId,
                    component: CouldNotRecognise,
                    props: { buttons }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                if (code === '220' || code === '230' || code === '240' || code === '270') {
                  buttons.push({
                    label: viewTipsButtonLabel,
                    onClick: () => {
                      setFrontIDParams({});
                      this.setState({ error: null, showCaptureTips: 'CouldntRecognise' });
                    }
                  });
                  const error = {
                    component: CouldNotRecognise,
                    props: { buttons }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                if (code !== '200') {
                  buttons.push({
                    // label: localizedString("tryAgain"),
                    label: viewTipsButtonLabel,
                    onClick: () => {
                      setFrontIDParams({});
                      // this.setState({ error: null })
                      this.setState({ error: null, showCaptureTips: 'CouldntRecognise' });
                    }
                  });
                  const error = {
                    // component: CouldNotRead,
                    component: CouldNotRecognise,
                    props: { buttons }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                if (poorQuality) {
                  buttons.push({
                    label: viewTipsButtonLabel,
                    onClick: () => {
                      setFrontIDParams({});
                      this.setState({ error: null, showCaptureTips: 'PoorQuality' });
                    },
                    dataTestId: 'betterImg-viewTipsBtn'
                  });
                  const error = {
                    component: PoorQuality,
                    props: {
                      buttons,
                      cropped: !poorQuality || poorQuality === 'NULL' ? imagefile : poorQuality
                    }
                  };
                  this.setState({
                    error,
                    isLoading: false,
                    progressBar: 0
                  });
                  return;
                }

                const country = cardData.countryCode;

                if (!params.backFile && captureBackCard) {
                  if (
                    cardData.cardType.match(/LICENCE/i) &&
                    country === 'AU' &&
                    ignoreBackCardOfAUStates.includes(cardData.state)
                  ) {
                    // Skip
                  } else if (cardData.cardType.match(/LICENCE/i) && country === 'UK') {
                    // Skip
                  } else {
                    this.setState({
                      idType: cardData.cardType,
                      isLoading: false,
                      progressBar: 0,
                      backOfCard: true
                    });
                    return;
                  }
                }

                const temp = cardData;
                // validate dates
                // temp['dateOfBirth'] = validateDate(temp['dateOfBirth']) ? temp['dateOfBirth'] : ''
                // temp['expiryDate'] = validateDate(temp['expiryDate']) ? temp['expiryDate'] : ''

                // format dates
                temp.dateOfBirth = this.formatDay(temp.dateOfBirth);
                temp.expiryDate = this.formatDay(temp.expiryDate);

                temp.countryOfIssue = cardData.country;
                setDocId(temp.documentId);

                if (
                  (cardData.cardType === 'Passport' || cardData.cardType === 'MRZ') &&
                  ENABLE_VISA_AFTER_PASSPORT &&
                  country &&
                  country.toUpperCase() !== 'NZ' &&
                  country.toUpperCase() !== 'AU'
                ) {
                  setExtractedInfo(temp);
                  let verifyDetails = { ...temp };
                  if (cardData.givenNames) {
                    const [firstName, middleName] = cardData.givenNames.split(' ');
                    verifyDetails = { ...verifyDetails, firstName, middleName };
                  }
                  this.setState({
                    idType: 'PASSPORT',
                    isLoading: false,
                    progressBar: 0,
                    showCaptureVisa: true,
                    verifyDetails
                  });
                  return;
                }

                // Validate Address
                if (!verify) {
                  APIs.status('reviewInfo');
                  setExtractedInfo(temp);
                  onNextStep({
                    tokenId: token,
                    geolocation,
                    idType: temp.cardType,
                    backOfCard,
                    verifyDetails: temp,
                    useIdNumber,
                    isOldTurkishDL,
                    captureVisa: false,
                    disableVerifyDetailsScreen
                  });
                } else {
                  this.setState({
                    isLoading: false,
                    isWaiting: true
                  });

                  await APIs.status('preparingCards').then(() => {
                    return APIs.checkApproval().then((status) => {
                      const { isWaiting } = this.state;
                      if (!isWaiting) {
                        return;
                      }

                      this.setState({ isWaiting: false });
                      if (status !== 'approved') {
                        const error = {
                          component: Recapture,
                          props: {
                            buttons: [
                              {
                                label: localizedString('recapture'),
                                onClick: () => {
                                  setFrontIDParams({});
                                  this.handleNextStep();
                                }
                              }
                            ]
                          }
                        };
                        this.setState({ error, isLoading: false });
                        return;
                      }
                      APIs.status('reviewInfo');
                      setExtractedInfo(temp);
                      onNextStep({
                        tokenId: token,
                        geolocation,
                        idType: temp.cardType,
                        backOfCard,
                        verifyDetails: temp,
                        useIdNumber,
                        isOldTurkishDL,
                        disableVerifyDetailsScreen
                      });
                    });
                  });
                }
              }
            )
            .catch((e) => {
              console.error(e);

              const error = {
                component: Error500,
                props: {
                  onTryAgain: () => {
                    setFrontIDParams({});
                    this.setState({
                      error: null,
                      isUploading: false,
                      isLoading: false,
                      isWaiting: false,
                      backOfCard: false
                    });
                  }
                }
              };
              this.setState({ error });
            });
        }
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              this.setState({
                error: null,
                isUploading: false,
                isLoading: false,
                isWaiting: false,
                backOfCard: false
              });
            }
          }
        };

        setFrontIDParams({});
        this.setState({ error });
      });
  }

  uploadVisaImage(imagefile) {
    APIs.status('capturingId');
    const { setFrontIDParams, onNextStep, onGoBack, flowType } = this.props;
    const { geolocation, verifyDetails } = this.state;
    const params = {
      visa: 'yes',
      idType: 'VISA',
      flowType,
      frontFile: imagefile
    };

    this.setState({ isUploading: true, showCaptureVisa: false, progressBar: 0 });

    APIs.uploadImage(
      params,
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => {
          this.setState({ progressBar: width });
        }
      },
      '/api/v4'
    )
      .then(({ status, msg: error, skip = false }) => {
        const nextStepOptions = { geolocation, verifyDetails };

        if (status === 'error') {
          this.setState({
            error,
            isUploading: false
          });
          if (skip) {
            setFrontIDParams({});
            onNextStep(nextStepOptions);
          }
          return;
        }

        this.setState({
          isUploading: false
        });

        setFrontIDParams({});
        onNextStep(nextStepOptions);
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  renderInvalidCardsModal = () => {
    const { showInvalidCardsModal } = this.state;
    const { exiturl } = document.body.dataset;

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => {
          this.setState({ showInvalidCardsModal: false });
          // APIs.cancel();
        },
        variant: 'transparent'
      },
      {
        label: localizedString('continue'),
        onClick: () => {
          window.location.href = exiturl;
        }
      }
    ];

    return (
      <Modal
        isOpen={showInvalidCardsModal}
        heading={localizedString('noDocumentNow')}
        description=""
        buttons={confirmBtns}
        small={false}
      >
        {localizedString('verifyYourIdentityUsingPhotoID1')}
        <br />
        <br />
        {localizedString('verifyYourIdentityUsingPhotoID2')}
      </Modal>
    );
  };

  render() {
    const { backurl } = document.body.dataset;
    const {
      progressBar,
      backOfCard,
      isUploading,
      isLoading,
      isWaiting,
      error,
      showCaptureVisa,
      idType,
      acceptableDocs = false,
      showCaptureTips = '',
      showCaptureDigitalIdScreen,
      showManualEntryScreen
    } = this.state;

    const { component: Error, props: errorProps } = error || {};
    const { onSelectLanguage, engine4Config, ipCountryCode, flowType } = this.props;

    const captureVisaProps = {
      onSkip: this.handleSkip,
      onUploadVisa: this.handleNextStep
    };

    const footerButtons = [];
    if (!Error) {
      if (isUploading || isLoading || isWaiting) {
        footerButtons.push({
          label: '   ',
          variant: 'transparent',
          dataTestId: 'cptrId-back'
        });

        if (!HIDE_FOOTER_UPLOAD_SPINNER) {
          footerButtons.push({
            label:
              isUploading && progressBar < 100
                ? localizedString('uploading')
                : localizedString('loading'),
            variant: 'transparent',
            loading: true,
            dataTestId: 'id-Uploading'
          });
        }
      } else {
        if (backurl || isShownPrivacy('FLOW_V2') || acceptableDocs) {
          footerButtons.push({
            label: localizedString('back'),
            variant: 'transparent',
            onClick: this.handleGoBack,
            dataTestId: 'cptrId-back'
          });
        }

        footerButtons.push({
          label: localizedString('captureMyID'),
          type: 'submit',
          onClick: () => this.onClickedFooterTakePhoto(),
          dataTestId: 'cptrId-btn'
        });
      }
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={showCaptureVisa ? this.handleCaptureVisa : this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
        {Error && <Error {...errorProps} />}
        {showCaptureTips !== '' && (
          <CaptureTips
            type={showCaptureTips}
            onHide={() => this.setState({ showCaptureTips: '' })}
            onCaptureAgain={() => this.onCaptureAgain()}
          />
        )}
        {showCaptureVisa && <CaptureVisa {...captureVisaProps} />}
        {showCaptureDigitalIdScreen && (
          <CaptureNSWDigitalDriverLicenceScreen
            onUploadDigitalImage={({ imageFile, idType }) => {
              this.uploadImage(imageFile, idType);
              this.setState({ showCaptureDigitalIdScreen: false });
            }}
            onCloseScreen={() => this.setState({ showCaptureDigitalIdScreen: false })}
          />
        )}
        {showManualEntryScreen && (
          <ManualEntryScreen
            onComplete={() => this.handleNextStepAfterManualScreen()}
            onCloseManualEntryScreen={() => this.setState({ showManualEntryScreen: false })}
            flowType={flowType}
          />
        )}
        {!showCaptureVisa &&
          !showCaptureDigitalIdScreen &&
          !showManualEntryScreen &&
          !Error &&
          showCaptureTips === '' && (
            <Page
              // isDark={backOfCard}
              buttons={footerButtons}
              forceFillViewPort
              onSelectLanguage={onSelectLanguage}
            >
              {backOfCard && (
                <BackOfCard idType={idType} onReady={() => this.onClickedCaptureBackCard()} />
              )}
              {isWaiting && <LoadingSpinner heading={localizedString('waitingForApproval')} />}
              {!backOfCard && (
                <IdSelectionContent
                  idType={idType}
                  acceptableDocs={acceptableDocs}
                  onShowCaptureDigitalIdScreen={() =>
                    this.setState({ showCaptureDigitalIdScreen: true })
                  }
                  onSelectManualEntry={() => this.setState({ showManualEntryScreen: true })}
                  isLoading={isUploading || isLoading || isWaiting}
                  progressBar={progressBar}
                  engine4Config={engine4Config}
                  ipCountryCode={ipCountryCode}
                  onShowAcceptableDocsClick={this.handleOnShowAcceptableDocsClick}
                />
              )}
              {
                /* exiturl && !backOfCard && !showCaptureVisa */ false && (
                  <div className={classes.link} style={{ marginBottom: 30 }}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="#"
                      onClick={this.openInvalidCardsModal}
                    >
                      {localizedString('noValidLicenceOrPassport')}{' '}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0"
                          maskUnits="userSpaceOnUse"
                          x="1"
                          y="1"
                          width="14"
                          height="14"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.75 12.625C8.23325 12.625 8.625 12.2332 8.625 11.75C8.625 11.2668 8.23325 10.875 7.75 10.875C7.26675 10.875 6.875 11.2668 6.875 11.75C6.875 12.2332 7.26675 12.625 7.75 12.625ZM7.95546 3.5778C9.69463 3.47422 11.0944 4.65443 11.0067 6.24794C10.9315 7.61275 10.053 8.62001 8.50004 9.25274C8.49857 9.6657 8.16335 10 7.75005 10C7.33583 10 7.00005 9.66422 7.00005 9.25001V8.72861C7.00005 8.40404 7.20882 8.1163 7.51738 8.01562C8.85907 7.57779 9.46471 6.96874 9.50895 6.16546C9.54398 5.52945 8.94177 5.02171 8.04464 5.07514C7.47708 5.10895 6.96992 5.64408 6.95896 5.96071C6.94464 6.37468 6.59744 6.69865 6.18347 6.68432C5.76951 6.67 5.44553 6.3228 5.45986 5.90883C5.49727 4.828 6.60595 3.65817 7.95546 3.5778Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0)">
                          <rect x="0" y="0" width="16" height="16" />
                        </g>
                      </svg>
                    </a>
                  </div>
                )
              }
              {this.renderInvalidCardsModal()}
            </Page>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Capture);

function mapStateToProps({ capture, appConfig, ipInfo }) {
  return {
    frontParams: capture.frontParams,
    engine4Config: appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null,
    ipCountryCode: ipInfo.ipCountryCode
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setExtractedInfo: (data) => dispatch(InfoAction.setExtractedInfo(data)),
    resetExtractedInfo: () => dispatch(InfoAction.resetExtractedInfo()),
    resetConfirmedInfo: () => dispatch(InfoAction.resetConfirmedInfo()),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
